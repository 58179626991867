import { Link } from '@mui/material';
import React from 'react';

const PageFooter = () => {

    return <footer style={{ textAlign: "center", margin: "2rem 0" }}>
        <div>
            <small>
                &copy;{new Date().getFullYear()} et-cal.com
            </small>
        </div>
        <div style={{fontSize:".6rem"}}>
                <Link color="primary" href="mailto:info@et-cal.com">ZekuTW</Link>
        </div>
    </footer>
}
export default PageFooter;