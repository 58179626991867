import React, { Component } from 'react';
import CalBloc from '../Bloc/CalBloc';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { COOKIE_MAX_AGE_SEC } from '../Config';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { FormLabel, Stack, Typography } from '@mui/material';

class CalSwitch extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };
    
    constructor(props) {
        super(props);

        const { cookies } = props;
        let isEt = cookies.get('isEt') === 'true';
        CalBloc.setCalET(isEt);
        this.state = {
            isEt: isEt
        };
    }
    
    handleSwitchChange = () => {
        let isEt = !this.state.isEt;
        
        CalBloc.setCalET(isEt);

        const { cookies } = this.props;
 
        cookies.set('isEt', isEt, { path: '/', maxAge: COOKIE_MAX_AGE_SEC});

        this.setState({
            isEt
        });
    }

    render() {
        return (
            <FormControl component="fieldset" variant="standard"  sx={{padding: '0 8px'}}>
                <FormGroup>
                    <Stack direction="row" spacing={.5} alignItems="center">
                        <Typography>GC</Typography>
                        <Switch 
                        checked={this.state.isEt}
                        onChange={this.handleSwitchChange}
                        inputProps={{ 'aria-label': 'Calendar Switch' }}
                        />
                        <Typography>EC</Typography>
                    </Stack>
                </FormGroup>
            </FormControl>
        );
    }
}

export default withCookies(CalSwitch);
