/**
 * Used to initialize jwt to be used by the app.
 */
import * as rxjs from "rxjs";
import axios from "axios";
//import jwt from 'jsonwebtoken';
//import util from 'util';
import { AUTH_TOKEN_URL, AUTH_TOKEN_CONF } from "../Config";
//const jwtVerifyAsync = util.promisify(jwt.verify);

const authSubject = new rxjs.BehaviorSubject();
const prvAuthSubject = new rxjs.BehaviorSubject();
const AuthBloc = {
    subAuth: () => authSubject.asObservable(),
    subPrvAuth: () => prvAuthSubject.asObservable(),
    hasToken: () => !!authSubject.getValue()?.access_token,
    hasPrvToken: () => !!prvAuthSubject.getValue(),
    getToken: async () => {
        if (AuthBloc.hasToken()) {
            let token = authSubject.getValue().access_token;
            return token;
        }
        return await AuthBloc.init();
    },
    getPrvToken: () => prvAuthSubject.getValue(),
    setPrvToken: (t) => prvAuthSubject.next(t),
    init: async () => {
        console.log("initializing token..")
        const params = new URLSearchParams({
            client_id: AUTH_TOKEN_CONF.client_id,
            grant_type: AUTH_TOKEN_CONF.grant_type,
            client_secret: AUTH_TOKEN_CONF.client_secret,
            scope: AUTH_TOKEN_CONF.scope
        });

        axios.post(AUTH_TOKEN_URL, params).then(async (auth) => {
            console.log("received auth: ", auth)
            if (auth && auth.data) {             
                authSubject.next(auth.data);
                return auth.data.access_token;
            }

        }).catch(err => {
            console.log("err", err);
            return null;
        })
    }
}
export default AuthBloc;