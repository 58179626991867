import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CalEventBloc from '../Bloc/CalEventBloc';
import AuthBloc from '../Bloc/AuthBloc';

import Logo from "../Assets/logo2.png";

let pages = [];
let settings = [];

const PageHeader = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [initials, setInitials] = useState();

    const { keycloak } = useKeycloak();

    const preparePages = () => {
        pages = [
            { name: "Home", url: "/" }
        ];
        if (!!keycloak.authenticated) {
            settings = ['Logout'];
            if (keycloak.isSA()) {
                pages.push({ name: "Admin", url: "/admin" });
            }
        } else {
            settings = ['Login'];
        }
        pages.push({ name: "About", url: "/about" });
    }

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (m) => {
        console.log("m: ", m);
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = (m) => {
        console.log("m: ", m);
        setAnchorElUser(null);

        if (m === "Login") {
            keycloak.login();
        }
        else if (m === "Logout") {
            keycloak.logout();
            CalEventBloc.deleteLocalPrvDb();
        }
    };
    useEffect(() => {

        const sub = AuthBloc.subPrvAuth().subscribe(v => {
            if(v) {
                setInitials(keycloak.initials)
            }
        })

        return () => {
            sub.unsubscribe();
        }
    }, [initials])

    preparePages();

    return (
        <AppBar position="static">
            <Container maxWidth="xl" className="app-bar-cont">
                <Toolbar disableGutters>
                    {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                            height: 44,
                            }}
                            alt="et-cal"
                            src={Logo}
                        />
                        
                    </Typography>
                    {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                        }}
                    >
                        
                        <Box
                            component="img"
                            sx={{
                            height: 44,
                            }}
                            alt="et-cal"
                            src={Logo}
                        />
                    </Typography>

                    <Box alignItems="right" justifyContent="right"
                        sx={{ flexGrow: 1, mr: 4, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page, i) => (
                            <Link key={i} style={{ textDecoration: "none" }}
                                to={page.url}>
                                <Button
                                    sx={{ my: 2, color: 'white', display: 'block' }}>
                                    {page.name}
                                </Button>
                            </Link>
                        ))}
                    </Box>

                    {/* <Box>                        
                        <IconButton>
                            <HelpIcon sx={{ fontSize: 28, verticalAlign: 'middle', color: '#757575' }} />
                        </IconButton>
                    </Box> */}

                    <Box alignItems="right" justifyContent="right" sx={{ flexGrow: 0, display: 'flex' }}>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-user"
                            aria-haspopup="true"
                            onClick={handleOpenUserMenu}
                        >
                            <Avatar color="primary" 
                                sx={{ width: 24, height: 24 }}>
                                {initials && <span style={{
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    paddingTop: '2px'
                                }}>{initials}</span>}
                            </Avatar>
                        </IconButton>
                        <Menu
                            sx={{ display: 'block' }}
                            id="menu-user"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box alignItems="right" justifyContent="right"
                        sx={{ flexGrow: 0, 
                                display: { xs: 'flex', md: 'none' },
                                marginRight: -1
                         }}>
                        <IconButton
                            aria-label="menu of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            sx={{
                                display: { xs: (pages.length ? 'block' : 'none'), md: 'none' },
                            }}
                        >
                            <MenuIcon sx={{ fontSize: 28, verticalAlign: 'middle' }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: (pages.length ? 'block' : 'none'), md: 'none' },
                            }}
                        >
                            {pages.map((page, i) => (
                                <MenuItem key={i} onClick={() => handleCloseNavMenu(page)}>
                                    <Typography textAlign="center">
                                        <Link to={page.url} style={{ textDecoration: "none", color: "inherit" }}
                                        >{page.name}</Link>
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default PageHeader;
