import React, { Component } from 'react';
import CacheBloc from '../Bloc/CacheBloc';

class CacheUpdate extends Component {
    state = {
        show: false
    }

    onOkay = () => {
        var registration = CacheBloc.geCacheUpdate();
        //console.log("reloading", registration)
        if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
        window.setTimeout(() => { window.location.reload() }, 200);
    }

    onCancel = () => {
        this.setState({
            show: false
        })
    }

    componentDidMount() {

       const sub =  CacheBloc.subCacheUpdate().subscribe((registration) => {
            // this.setState({
            //     show: registration && registration.waiting
            // })
            if(registration && registration.waiting) {
                sub.unsubscribe();
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                window.setTimeout(() => { window.location.reload() }, 200);
            }
        })
    }
    render() {
        return this.state.show
        ? <div className="cache-ref">
            <small>
                <div>Et-Cal has been update. Do you want reload now?</div>
                <span onClick={() => this.onOkay()} className="btn btn-outline-success">Yes</span> 
                <span onClick={() => this.onCancel()} className="btn btn-outline-success">No</span>
            </small>
        </div>
        : null;
    }
}
export default CacheUpdate;
