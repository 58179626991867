import * as rxjs from "rxjs";

const cacheUpdatedSubject = new rxjs.BehaviorSubject(null);

const CacheBloc = {
    subCacheUpdate: () => cacheUpdatedSubject.asObservable(),
    geCacheUpdate: () => cacheUpdatedSubject.getValue(),

    onUpdate: (registration) => {
        //console.log("cache updated.", registration);
        cacheUpdatedSubject.next(registration)

        // // TODO: get user confirmation for the following action before reloading
        // //alert('New version available!  Ready to update?');
        // if (registration && registration.waiting) {
        //     registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        // }
        // window.setTimeout(() => { window.location.reload() }, 500);
        // // ------------------------------
    },
    onSuccess: (registration) => {
        //console.log("cached.", registration);
    }
}

export default CacheBloc;