import PouchDB from 'pouchdb'
import { POUCH_DB } from '../Config';
import DataBloc from "../Bloc/DataBloc";

const PouchDbUtils = {
    onSyncChange: (change, dbName) => {
        DataBloc.setInit(dbName);     
        console.log("onSyncChange " + dbName, change);
    },
    onSyncPaused: (e, dbName) => {
        console.log("onSyncPaused " + dbName, e);
    },
    onSyncError: (e, dbName) => {
        console.log("onSyncError " + dbName, e);
    },
    onSyncComplete: (info, dbName) => {
        console.log("** onSyncComplete " + dbName, info)
    },    
    
    onDeleted: (id, dbName) => {
        console.log("onDeleted " + dbName, id);
        DataBloc.deleteData(dbName, id)
    },
    onUpdatedOrInserted: (newDoc, dbName) => {
        console.log("onUpdatedOrInserted " + dbName, newDoc);
        DataBloc.addOrUpdate(dbName, newDoc);
    },
    reactToChanges: (db, dbName) => {
        console.log("reactToChange " + dbName);
        var onDeleted = PouchDbUtils.onDeleted;
        var onUpdatedOrInserted = PouchDbUtils.onUpdatedOrInserted;

        db.changes({ live: true, since: 'now', include_docs: true }).on('change', function (change) {
            console.log("db changed " + dbName, change);
            if (change.deleted) {
                // change.id holds the deleted id
                onDeleted(change.id, dbName);
            } else { // updated/inserted
                // change.doc holds the new doc
                onUpdatedOrInserted(change.doc, dbName);
            }
            //renderDocsSomehow();
        }).on('error', console.log.bind(console));
    },
    fetchInitialDocs: (db, dbName) => {
        //console.log("fetchInitialDocs " + dbName)

        return db.allDocs({ include_docs: true }).then(function (res) {
            DataBloc.setInit(dbName, res.rows );
        });
    },    
    getAllDocs: (dbName) => {
        //console.log("getAllDocs " + dbName)
        var db = new PouchDB(dbName);

        return db.allDocs({ include_docs: true })
                .then(function (res) {
                    
                    return res.rows;
                });
    },
    syncAll: () => {
        //console.log("syncAll");
        POUCH_DB.syncedDbs.forEach(db => {
            PouchDbUtils.syncDb(db.couchDbUrl, db.couchDbAuth, db.name, db.fetchOnDemand);
        });
    },
    syncDb: (couchUrl, couchDbAuth, dbName, fetchOnDemand) => {
        let db = new PouchDB(dbName);
        
        let remoteurl = couchUrl + dbName;
        var remoteDB = new PouchDB(remoteurl, {
            fetch: function (url, opts) {                
                opts.headers.set('Authorization', couchDbAuth);
                return PouchDB.fetch(url, opts);
              }
        });
        var opts = { live: true, retry: true };

        console.log("remoteDB", remoteDB);

        var onSyncChange = PouchDbUtils.onSyncChange;
        var onSyncPaused = PouchDbUtils.onSyncPaused;
        var onSyncError = PouchDbUtils.onSyncError;
        var onSyncComplete = PouchDbUtils.onSyncComplete;
        var reactToChanges = PouchDbUtils.reactToChanges;
        var fetchInitialDocs = PouchDbUtils.fetchInitialDocs

        //console.log("replicating from ", remoteDB);
        // do one way, one-off sync from the server until completion
        db.replicate.from(remoteDB).on('complete', function (info) {
            console.log("syncing " + dbName);
            // then two-way, continuous, retryable sync
            db.sync(remoteDB, opts)
                .on('change', (e) => onSyncChange(e, dbName))
                .on('complete', (e) => onSyncComplete(e, dbName))
                .on('paused', (e) => onSyncPaused(e, dbName))
                .on('error', (e) => onSyncError(e, dbName));

            if(fetchOnDemand) {
                DataBloc.setInit(dbName);
            }
            else {
                fetchInitialDocs(db, dbName)
                .then(reactToChanges(db, dbName))
                .catch(console.log.bind(console));
            }
        })
        .on('error', onSyncError);
    },
    usernameToDbName: (name) => {
        return 'userdb-' + Buffer.from(name).toString('hex');
    },
    dbNameToUsername: (prefixedHexName) => {
        return Buffer.from(prefixedHexName.replace('userdb-', ''), 'hex').toString('utf8');            
    }
}
export default PouchDbUtils;