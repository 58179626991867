import React from "react";
//import './Admin.css';
import CalEventBloc from "./Bloc/CalEventBloc";
import ConfirmButton from "./components/ConfirmButton";
import { useKeycloak } from "@react-keycloak/web";
import LogUtils from "./utils/LogUtils";
import { Button, Stack } from "@mui/material";
import { Container } from "react-bootstrap";
import { Box } from "@mui/system";

const Admin = () => {
    const { keycloak } = useKeycloak();
    if(!keycloak.isSA()) return null;

    return (<Box sx={{m: 1, pt: 2}}>
        <Stack spacing={2}>
            <ConfirmButton  variant="outlined" onConfirm={() => CalEventBloc.restorePubHolidays()} buttonText="Restore Public Holidays"
                            title="Confirm" description="This will revert any changes to public holidays to the original list" confirmButtonText="Continue"  />
                <Button fullWidth variant="outlined" onClick={() => LogUtils.enableLog()}>Enable Log</Button>
        </Stack>
        </Box>)
}
export default Admin;