import React, { useEffect, useState } from 'react'
import { getHeaders, getGCWeeks } from '../utils/CalHelper'
import CalDay from "./CalDay"
import CalController from './CalController';
import CalBloc from '../Bloc/CalBloc'
import CalEventBloc from '../Bloc/CalEventBloc';
import { dayNames, geezDayNames } from '../utils/CalHelper'
import CalEvents from './CalEvents';
import EditCalEvent from './EditCalEvent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "./Cal.css"

import { styled } from '@mui/material/styles';
import SwipeableDiv from './SwipeableDiv';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#0e100e' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    marginTop: '.25rem',
    color: theme.palette.text.secondary,
  }));

const Cal = () => {
    const [headers, setHeaders] = useState([]);
    const [weeks, setWeeks] = useState([]);
    
    const setUpCal = () => {
        CalEventBloc.setCurMonth(null);

        let selY = CalBloc.getYear();
        let selM = CalBloc.getMonth();
        let selDate = CalBloc.getSelDate();
        let isCalET = CalBloc.getCalET();
        let headers = getHeaders(isCalET ? geezDayNames : dayNames).map((w, i) => {
            return (
                <TableRow key={i} className="cal-header">{w.days.map((d, k) => (
                    <TableCell key={k} sx={{p:0}} >{d.day}</TableCell>
                ))}</TableRow>);
        });

        let weeks = getGCWeeks(selY, selM, isCalET).map((w, i) => {
            return <TableRow key={i}>
                {w.days.map((d, k) => (
                    <TableCell  key={k}  sx={{p:0}}>
                        <CalDay data={d} month={selM} year={selY} selDate={selDate} />
                    </TableCell>
                ))}
            </TableRow>;
        });

        setHeaders(headers);
        setWeeks(weeks);
    }
   
    useEffect(() => {
        const subY = CalBloc.subYear().subscribe((v) => {
            setUpCal();
        })

        const subM = CalBloc.subMonth().subscribe((v) => {
            setUpCal();
        })

        const subEt = CalBloc.subCalET().subscribe((v) => {
            setUpCal();
        })

        const subD = CalBloc.subDay().subscribe((v) => {
            setUpCal();
        })

        const subSel = CalBloc.subSelDate().subscribe((v) => {
            setUpCal();
        })

        const subPH = CalEventBloc.subCalEvents().subscribe((v) => {
            setUpCal();
        })

        const subs = [subY,subM,subD,subEt,subSel, subPH];

        CalBloc.init();
        //CalEventBloc.initRemote();
        CalEventBloc.init();
        setUpCal();

        return () => {
            subs.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }, [])
        
    //console.log("** cal render")
    return (
        <>
            <CalController />
            <Item>
                <SwipeableDiv>
                    <TableContainer>
                        <Table size="small" className="cal">
                            <TableHead>
                                {headers}
                            </TableHead>
                            <TableBody>
                                {weeks}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </SwipeableDiv>
            </Item>
            <CalEvents />
            <EditCalEvent />
        </>
    )
    
}
export default Cal;