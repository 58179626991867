import React from 'react';
import { version } from '../package.json'
import { styled } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    padding: '1rem',
    color: theme.palette.text.secondary,
}));

const About = () => {
    return (
    <Item>
        <Box sx={{
            maxWidth: '600px',
            margin: '0 auto',
            fontSize: '1.1rem'
        }}>
        <div>
            et-cal 
        </div>
        <div>(Ethiopian Calendar Converter)</div>
        <div>
            v{version}
        </div>
        <div>
            by <Link href='mailto:info@et-cal.com' sx={{fontSize:'small'}} >ZekuTW</Link>
        </div>
        <div style={{ textAlign: 'left' }}>
            <p>
                <h4>About et-cal</h4>
                et-cal is an application for converting Ethiopian Calendar to Gregorian 
                Calendar or vice versa. It is built and maintained by Zeku TW as a hobby. 
            </p>
            <p>
                
            </p>

            <p>
                <h4>About Ethiopian calendar<br/>
                    <small>
                     [<Link href='https://en.wikipedia.org/wiki/Ethiopian_calendar' target='_blank'>From Wikipedia</Link>]
                     </small>
                </h4>
                The Ethiopian Calendar is the official calendar in Ethiopia.
                It is used as both the civil calendar (in Ethiopia) and an
                ecclesiastical calendar (in Ethiopia and Eritrea). 
            </p>
            <p>
                The Ethiopian calendar is a solar calendar
                that has more in common with the Coptic calendar of the Coptic Orthodox Church of Alexandria
                and Coptic Catholic Church, but like the Julian calendar, it adds a leap day every four years
                without exception, and begins the year on 29 August or 30 August in the Julian calendar.
            </p>
            <p>
                A gap of seven to eight years between the Ethiopian and Gregorian calendars results from an
                alternative calculation in determining the date of the Annunciation.</p>
            <p>The Ethiopian calendar has twelve months of thirty days plus five or six epagomenal days,
                which comprise a thirteenth month. The Ethiopian months begin on the same days as those of
                the Coptic calendar, but their names are in Ge'ez. A sixth epagomenal day is added every four
                years, without exception, on 29 August of the Julian calendar, six months before the corresponding
                Julian leap day. Thus the first day of the Ethiopian calendar year, 1 Mäskäräm, for years between
                1900 and 2099 (inclusive), is usually 11 September (Gregorian). It falls on 12 September in years
                before the Gregorian leap year, however.</p>
        </div>
        </Box>
    </Item>)
}
export default About;
