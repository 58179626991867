import React, { useEffect, useState } from "react";
import AuthBloc from "../Bloc/AuthBloc";
import CalBloc from "../Bloc/CalBloc";
import CalEventBloc from "../Bloc/CalEventBloc";
import ConfirmButton from "./ConfirmButton";
import { useKeycloak } from "@react-keycloak/web";
import { Button, Grid, TextField } from "@mui/material";
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const EditCalEvent = () => {
    const [eventTypes, setEventTypes] = useState([]);
    const [day, setDay] = useState();
    const [eventTitle, setEventTitle] = useState("");
    const [editEvent, setEditEvent] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [selEventType, setSelEventType] = useState("Private")
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const { keycloak } = useKeycloak();

    const updateEventTypes = () => {
        let types = [];
        if (keycloak.authenticated) {
            types.push("Private");

            if (keycloak.isSA() && AuthBloc.hasToken()) {
                types.push("Public");
            }
        }

        setEventTypes(types);
    }

    const save = () => {
        if (!eventTitle) {
            return;
        }


        var options = { month: 'short', day: 'numeric', year: 'numeric' };
        let calEvent = {};
        const isPrivate = selEventType === "Private";
        if (editEvent) {
            calEvent = { ...editEvent };
            calEvent.title = eventTitle;
        }
        else {
            calEvent = {
                type: isPrivate ? "prv-cal-event" : "et-pub-hol",
                day: day.toLocaleDateString("en-US", options),
                title: eventTitle
            }
        }

        if (isPrivate) {
            CalEventBloc.savePrivateEvent(calEvent);
        }
        else {
            CalEventBloc.savePubEvent(calEvent);
        }

        setEventTitle("");
        setIsEditing(false);
        setEditEvent(null);
        setOpen(false);
    }

    const deleteEvent = () => {
        if (editEvent) {
            editEvent._deleted = true
            if (editEvent.isPrivate) {
                CalEventBloc.savePrivateEvent(editEvent);
            } else {
                CalEventBloc.savePubEvent(editEvent);
            }
            setIsEditing(false);
            setEditEvent(null);
            setOpen(false);
        }
    }

    const cancel = () => {
        setEventTitle("");
        setIsEditing(false);
        setEditEvent(null);
        setOpen(false);
    }

    useEffect(() => {
        const subPA = AuthBloc.subPrvAuth().subscribe(t => {
            if(t) {
                updateEventTypes();
            }
        })

        const subA = AuthBloc.subAuth().subscribe(t => {
            updateEventTypes();
        })
        const subDay = CalBloc.subSelDate().subscribe(v => {
            setDay(v);
        })
        const subSelEvent = CalEventBloc.subSelCalEvent().subscribe(v => {
            setEditEvent(v);
            setEventTitle(v?.title || "");
            setIsEditing(!!v);
            setOpen(!!v);
        })
        return () => {
            subA.unsubscribe();
            subPA.unsubscribe();
            subDay.unsubscribe();
            subSelEvent.unsubscribe();
        }
    }, []);

    // console.log("eventTypes: ", eventTypes);
    if (eventTypes.length === 0) return null;

    return (<>
        {!isEditing && <Button variant="outlined" color="success" sx={{mt: 1}}
            fullWidth onClick={() => { setIsEditing(true); setOpen(true) }}>
            Add New Event
        </Button>}
        <Dialog 
            onSubmit={e => {e.preventDefault(); save()}}
            open={open}
            onClose={cancel}
            sx={{top: '-30%'}}
            aria-labelledby="dialog-title"
        >
            <DialogTitle id="dialog-title" sx={{color: theme.palette.text.secondary}}>
                            {editEvent ? "Edit" : "Add New"} Event
            </DialogTitle>
            <DialogContent sx={{px: '16px'}}>
                <DialogContentText component="div">
                    <Box
                        sx={{
                            width: 500,
                            maxWidth: '100%'
                        }}
                        noValidate
                        autoComplete="off"
                        
                    >
                        <FormControl sx={{ textAlign: 'center' }} fullWidth >
                            {!editEvent && eventTypes.length > 1 && <RadioGroup
                                row sx={{ display: 'inline' }}
                                aria-labelledby="event-type-buttons-group-label"
                                name="row-radio-buttons-group">
                                {
                                    eventTypes.map((eventType, i) => (
                                        <FormControlLabel key={i} value={eventType} control={<Radio />}
                                            label={eventType} onChange={() => setSelEventType(eventType)}
                                            checked={selEventType === eventType ? true : false} />
                                    ))
                                }
                            </RadioGroup>}
                        </FormControl>

                        <TextField id="outlined-basic" size="small" sx={{ margin: '1rem 0' }}
                            label="Event Title" variant="outlined" fullWidth autoFocus
                            maxRows={4} multiline inputProps={{maxLength: 400}}
                            value={eventTitle} onChange={(e) => setEventTitle(e.target.value)} />
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button  onClick={save}>
                    Save
                </Button>
                <Button  onClick={cancel}>
                    Cancel
                </Button>
                    {editEvent && <ConfirmButton onConfirm={deleteEvent} buttonText="Delete"
                                    title="Confirm" description="Are you sure you want to delete?" confirmButtonText="Delete" />
                            }
            </DialogActions>
        </Dialog>
    </>)


}
export default EditCalEvent;