
const _logger = function() {
    let oldConsoleLog = null;
    let oldConsoleWarn = null;
    let oldConsoleError = null;
    //let oldConsoleInfo =  null;
    const pub = {};
    pub.enableLogger = async function enableLogger() {
        if (oldConsoleLog != null) {
            window['console']['log'] = oldConsoleLog;        
        }
        if (oldConsoleWarn != null) {
            window['console']['warn'] = oldConsoleWarn;        
        }
        if (oldConsoleError != null) {
            window['console']['error'] = oldConsoleError;
        }
        // if (oldConsoleInfo != null) {
        //     window['console']['info'] = oldConsoleInfo;
        // }
        console.log("Log enabled. *1*");
    };
    pub.disableLogger = async function disableLogger() {
        oldConsoleLog = console.log;
        window['console']['log'] = function() {};
        
        oldConsoleWarn = console.warn;
        window['console']['warn'] = function() {};
        
        oldConsoleError = console.error;
        window['console']['error'] = function() {};

        // oldConsoleInfo = console.info;
        // window['console']['info'] = function() {};
    };
    return pub;
}();

const LogUtils = {
    async disableLog() {
        await _logger.disableLogger();
    },
    enableLog() {
        _logger.enableLogger();
    }
}

export default LogUtils;