//import * as rxjs from "rxjs";

const DataBloc = {
    
    // ===================================
    // END - DATABASE SYNCS
    // ===================================

    // common
    setInit: (dbName, d) => {
        console.log("setInit", dbName, d);          
    },
    addOrUpdate: (dbName, d) => {
        console.log("addOrUpdate", dbName, d);
    },
    deleteData: (dbName, d) => {
        console.log("deleteData", dbName, d);
    }

}
export default DataBloc