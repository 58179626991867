import * as rxjs from "rxjs";
import ZCal from '../utils/ZCal';
import * as CalHelper from '../utils/CalHelper';

const initSubject = new rxjs.BehaviorSubject(false);
const calETSubject = new rxjs.BehaviorSubject(false);
const yearSubject = new rxjs.BehaviorSubject(new Date().getFullYear());
const monthSubject = new rxjs.BehaviorSubject(new Date().getMonth());
const daySubject = new rxjs.BehaviorSubject(new Date().getDate());
const selDateSubject = new rxjs.BehaviorSubject(new Date());

const CalBloc = {
    isInitialised: () => initSubject.getValue(),
    subCalET: () => calETSubject.asObservable(),
    subYear: () => yearSubject.asObservable(),
    subMonth: () => monthSubject.asObservable(),
    subDay: () => daySubject.asObservable(),
    subSelDate: () => selDateSubject.asObservable(),

    getCalET: () => {
        var v =   calETSubject.getValue()
        //console.log("getcalet", v);
        return v;
    },
    setCalET: (v) => {
        calETSubject.next(v);

        var selY = CalBloc.getYear();
        var selM = CalBloc.getMonth();
        var selD = CalBloc.getDay();

        //console.log("selY, selM, selD", selY, selM, selD);
        
        var zcal = new ZCal();
        zcal.initYMD(selY, selM+1, selD);

        var dateParts = CalBloc.getCalET() ? zcal.gregorianToEthiopic() : zcal.ethiopicToGregorian();
        //console.log("dateParts", dateParts);
        
        CalBloc.setYear(dateParts[0]);
        CalBloc.setMonth(dateParts[1] - 1);
        CalBloc.setDay(dateParts[2]);
    },

    getYear: () => yearSubject.getValue(),
    setYear: (y) => yearSubject.next(y),
    
    getMonth: () => monthSubject.getValue(),
    setMonth: (m) => monthSubject.next(m),

    getDay: () => daySubject.getValue(),
    setDay: (d) => daySubject.next(d),

    getSelDate: () => selDateSubject.getValue(),
    setSelDate: (d) => selDateSubject.next(d),

    addToYear: (v) => {
        CalBloc.setYear(CalBloc.getYear() + v);
        //console.log("test cache 8")
    },
    addToMonth: (v) => {
        var month = CalBloc.getMonth() + v;
        var year = CalBloc.getYear();

        var isCalEt = CalBloc.getCalET();

        if( month < 0)
        {
            year--;
            month = isCalEt ? 12 : 11;

            CalBloc.setYear(year);
            CalBloc.setMonth(month)
        }
        else if (!isCalEt && month > 11) {
            year++;
            month = 0;

            CalBloc.setYear(year);
            CalBloc.setMonth(month)
        }
        else if (isCalEt && month > 12) {
            year++;
            month = 0;

            CalBloc.setYear(year);
            CalBloc.setMonth(month)
        }
        else {
            
            CalBloc.setMonth(month)
        }

        CalBloc.setDay(1); // 

    },
    resetCal: () => {
        CalBloc.init();
    },
    isCurrentMonth: () => {
        var d1 = new Date();
        var d2 = new Date(CalBloc.getYear(), CalBloc.getMonth(), CalBloc.getDay());
        if(CalBloc.getCalET()) {
            var zcal = new ZCal();
            zcal.initYMD(CalBloc.getYear(), CalBloc.getMonth()+1, CalBloc.getDay());
            var dp = zcal.ethiopicToGregorian();
            d2 = new Date(dp[0], dp[1]-1, dp[2]);
        }

        return d1.getMonth() === d2.getMonth()
                && d1.getFullYear() === d2.getFullYear()
                && d1.getDate() === d2.getDate();
    },
    getCurrentMonthName: () => {
        var mName =  CalBloc.getCalET() ? CalHelper.geezMonthNames[CalBloc.getMonth()] : CalHelper.monthNames[CalBloc.getMonth()];

        return  mName  + ' ' + CalBloc.getDay() + ', ' + CalBloc.getYear()
    },
    getCurrentMonthNameForSecCal: () => {
        var y = CalBloc.getYear();
        var m = CalBloc.getMonth();
        var d = CalBloc.getDay();
        var mName;
        var dp;
        var zCal = new ZCal();
        zCal.initYMD(y, m+1, d);

        if(CalBloc.getCalET()) {
            dp = zCal.ethiopicToGregorian();
            mName = CalHelper.monthNames[dp[1]-1];
        }
        else {
            dp = zCal.gregorianToEthiopic();
            mName = CalHelper.geezMonthNames[dp[1]-1];
        }

        return mName + ' ' + dp[2] + ', ' + dp[0];
    },
    init: () => {
        var y = new Date().getFullYear();
        var m = new Date().getMonth();
        var d = new Date().getDate();

        if(CalBloc.getCalET()) {        

            var zCal = new ZCal();
            zCal.initYMD(y, m+1, d);

            var dateParts = zCal.gregorianToEthiopic();

            y = dateParts[0];
            m = dateParts[1]-1;
            d = dateParts[2]; 
        }

        CalBloc.setYear(y);
        CalBloc.setMonth(m);
        CalBloc.setDay(d);
        CalBloc.setSelDate(new Date());

        initSubject.next(true);
    }
}

export default CalBloc;