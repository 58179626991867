import React from 'react';
import Cal from './components/Cal';


const Home = () => {

    return (
        <Cal />
    )
}
export default Home;