import React from 'react'
import ZCal from '../utils/ZCal'
import * as CalHelper from '../utils/CalHelper'
import CalBloc from '../Bloc/CalBloc'
import CalEventBloc from '../Bloc/CalEventBloc'
import { Box } from '@mui/material'

function CalDay (props) {
    var {data, month, year, selDate} = props;
    //console.log("setup cal day",year, month, selDate)
    var cssClass = "day";
    var d1 = {};
    var d2 = {};
    var d3 = {};
    var myDate;

    if(data.day) {
        if(CalBloc.getCalET()) {
            let etCal = new ZCal();    
            etCal.initYMD(year, month+1, data.day);    
            var dp = etCal.ethiopicToGregorian();

            myDate = new Date(dp[0], dp[1]-1, dp[2]);
            cssClass = CalHelper.isSameDate(
                            selDate, 
                            myDate ) 
                        ? "day current" 
                        : "day"

            d1.value = data.day;
            d2.value = dp[2];
            d3.value = CalHelper.geezNums[data.day-1];

            d1.cssClass = 'd1 et';
            d2.cssClass = 'd2 gc';
            d3.cssClass = 'd3 et';

            d1.color = "primary";
            d2.color = "secondary";
            d3.color = "primary";
        } 
        else {
            
            var dte = myDate = new Date(year, month, data.day);

            let etCal = new ZCal(dte);        
            var etDate = etCal.gregorianToEthiopic();

            cssClass = CalHelper.isSameDate(
                            selDate, 
                            myDate ) 
                        ? "day current" 
                        : "day"

            d1.value = data.day;
            d2.value = etDate[2];
            d3.value = CalHelper.geezNums[etDate[2]-1];

            d1.cssClass = 'd1 gc';
            d2.cssClass = 'd2 et';
            d3.cssClass = 'd3 et';
            
            d1.color = "primary";
            d2.color = "secondary";
            d3.color = "secondary";
        }
        var arr = CalEventBloc.getCalEventsForDate(myDate);
        if(arr) {
            arr.forEach(ph => {                
                //console.log("ph: ", ph);
                ph.d = d1.value
                CalEventBloc.addToCurMonth(ph);
            });
            cssClass += " ph";
        }
        
        return (<div className={cssClass} onClick={(e) => {
                    CalBloc.setDay(data.day);
                    CalBloc.setSelDate(myDate);
                }}>
                    
            <Box component="div" color={d1.color}  className={d1.cssClass}>
                
                {d1.value}
            </Box>
            <div className="lev2">
                <Box component="span" color={d2.color} className={d2.cssClass}>{d2.value}</Box>
                <Box component="span" color={d3.color} className={d3.cssClass}>{d3.value}</Box>
            </div>
        </div>)
    }
    else {
        return null;
    }
}
export default CalDay;