import {logCalEnabled} from '../Config';
import ZCal from './ZCal';

export const geezNums = ['፩','፪','፫','፬','፭','፮','፯','፰','፱','፲','፲፩','፲፪','፲፫','፲፬','፲፭','፲፮','፲፯','፲፰','፲፱','፳','፳፩','፳፪','፳፫','፳፬','፳፭','፳፮','፳፯','፳፰','፳፱','፴'];
export const geezMonthNames = ['መስከረም','ጥቅምት','ኅዳር','ታኅሣሥ','ጥር','የካቲት','መጋቢት','ሚያዝያ','ግንቦት','ሰኔ','ሐምሌ','ነሐሴ','ጳጉሜ'];
export const geezDayNames = ['ሰኞ','ማክሰኞ','ረቡዕ','ሐሙስ','ዓርብ','ቅዳሜ','እሑድ'];
export const dayNames = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']
//export const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const getFirstDayOfWeek = (year, month, isCalET) => {
    var d = 0;
    if(isCalET) {
        var zCal = new ZCal();
        zCal.initYMD(year, month+1, 1);
        var dp = zCal.ethiopicToGregorian();
        var date2 = new Date(dp[0], dp[1]-1, dp[2]);
        d = date2.getDay();
    }
    else {
        var date = new Date(year, month, 1);
        d = date.getDay();
    }
    //  Sunday is 0, Monday is 1 ..
    // As sunday is the last day on out calendar make the number to be 7
    return d === 0 ? 7 : d; 
}
export const getDaysInMonth = (year, month, isCalET) => {
    if(isCalET) {
        if(month === 12) {
            // todo: check if leap year
            var zCal = new ZCal();
            zCal.initYMD(year, month+1,1);
            var dp = zCal.ethiopicToGregorian();

            return isLeapYear(dp[0]+1) ? 6 : 5;
        }
        else {
            return 30;
        }
    }
    
    trace("getDaysInMonth: " + (new Date(year, month+1, 0)).getDate());
    return (new Date(year, month+1, 0)).getDate();
}

export const getRange = (min, max) => {
    var r = [];
    for (var i = min; i <= max; i++) {
        r.push(i);
    }
    return r;
}

export const getHeaders = (names) => {
    var headers = [{ day: names[0]}, { day: names[1]}, { day: names[2] }, { day: names[3] }, { day: names[4] }, { day: names[5] }, { day: names[6]}];

    var weekHeaders = [{ days: headers}];

    return weekHeaders;
}

export const getGCWeeks = (selY, selM, isCalET) => {
    var d = 0;
    var weeks = []
    var days;
    var hasDays
    var firstDay = getFirstDayOfWeek(selY, selM, isCalET);
    var maxDays = getDaysInMonth(selY, selM, isCalET);

    for (var i = 0; i < 6; i++) {
        days = [];
        hasDays = false;
        for (var j = 1; j <= 7; j++) {
            if (i > 0 || j >= firstDay) {
                d++;
                if (d > 0 && d <= maxDays) {
                    days.push({ 
                        day: d
                    });
                    hasDays = true;
                }
                else
                    days.push({ day: '' });
            }
            else {
                days.push({ day: '' });
            }
        }

        if(hasDays) weeks.push({ days: days });
    }
    return weeks;
}

export const isSameDate = (d1, d2) => {
    return d1.getDate() === d2.getDate()
            && d1.getMonth() === d2.getMonth()
            && d1.getFullYear() === d2.getFullYear()
            
}

export const isLeapYear = (year) => {
  return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

const trace = (msg) => {
    if(logCalEnabled) console.log('ET Cal: ' + msg);
}