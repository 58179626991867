import {logCalEnabled} from '../Config'

class ZCal   {
    constructor(date) {
        this.initialize();
        if(date) this.initDate(date);
    };
    initialize = function () {
        this.JD_EPOCH_OFFSET_AMETE_ALEM = -285019;
        this.JD_EPOCH_OFFSET_AMETE_MIHRET = 1723856;
        this.JD_EPOCH_OFFSET_COPTIC = 1824665;
        this.JD_EPOCH_OFFSET_GREGORIAN = 1721426;
        this.JD_EPOCH_OFFSET_UNSET = -1;
        this.nMonths = 12;
        this.monthDays = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        this.jdOffset = this.JD_EPOCH_OFFSET_UNSET;
        this.year = -1;
        this.month = -1;
        this.day = -1;
        this.dateIsUnset = true;

        this.setEra(null);

        this.log('ZCal Initialised');
    };
    initDate = function (date) {
        this.myDate = date;
        this.year = date.getFullYear();
        this.month = (date.getMonth() + 1);
        this.day = date.getDate();
        this.dateIsUnset = false;

        this.logDates();
    };
    initYMD = function (year, month, day) {
        this.year = year;
        this.month = month;
        this.day = day;
        this.myDate = new Date(year, month - 1, day);
        this.dateIsUnset = false;

        this.logDates();
    };
    getDay = function () {
        return this.day;
    };
    setDay = function (day) {
        this.day = day;
    };
    getMonth = function () {
        return this.month;
    };
    setMonth = function (month) {
        this.month = month;
    };
    getYear = function () {
        return this.year;
    };
    setYear = function (year) {
        this.year = year;
    };
    getEra = function () {
        return this.jdOffset;
    };
    getDate = function () {
        var date = [this.year, this.month, this.day, this.jdOffset];
        return date;
    };
    setEra = function (era) {
        if (!era)
            era = this.JD_EPOCH_OFFSET_AMETE_MIHRET;

        if ((this.JD_EPOCH_OFFSET_AMETE_ALEM === era) || (this.JD_EPOCH_OFFSET_AMETE_MIHRET === era)) {
            this.jdOffset = era;
        }
        else {
            throw new Error("Unknown era: " + era + " must be either ?/? or ?/?.");
        }
    };
    isEraSet = function () {
        return (this.JD_EPOCH_OFFSET_UNSET === this.jdOffset) ? false : true;
    };
    unsetEra = function () {
        this.jdOffset = this.JD_EPOCH_OFFSET_UNSET;
    };
    unset = function () {
        this.unsetEra();
        this.year = -1;
        this.month = -1;
        this.day = -1;
        this.dateIsUnset = true;
    };
    isDateSet = function () {
        return (this.dateIsUnset) ? false : true;
    };

    /*
    ** ********************************************************************************
    **  Conversion Methods To/From the Ethiopic & Gregorian Calendars
    ** ********************************************************************************
    */
    ethiopicToGregorian$1 = function (era) {
        if (!this.isDateSet()) {
            throw new Error("Unset date.");
        }
        return this.ethiopicToGregorian$3(this.year, this.month, this.day, era);
    };
    ethiopicToGregorian$3 = function (year, month, day, era) {
        this.setEra(era);
        var date = this.ethiopicToGregorian$2(year, month, day);
        this.unsetEra();
        return date;
    };
    ethiopicToGregorianDate = function () {
        if (this.dateIsUnset) {
            throw new Error("Unset date.");
        }
        var gc = this.ethiopicToGregorian$2(this.year, this.month, this.day);
        return new Date(gc[0], gc[1], gc[2]);
    };
    ethiopicToGregorian = function () {
        if (this.dateIsUnset) {
            throw new Error("Unset date.");
        }
        return this.ethiopicToGregorian$2(this.year, this.month, this.day);
    };
    ethiopicToGregorian$2 = function (year, month, day) {
        if (!this.isEraSet()) {
            if (year <= 0) {
                this.setEra(this.JD_EPOCH_OFFSET_AMETE_ALEM);
            }
            else {
                this.setEra(this.JD_EPOCH_OFFSET_AMETE_MIHRET);
            }
        }

        var jdn = this.ethiopicToJDN$2(year, month, day);
        return this.jdnToGregorian(jdn);
    };
    gregorianToEthiopic = function () {
        if (this.dateIsUnset) {
            throw new Error("Unset date.");
        }
        return this.gregorianToEthiopic$1(this.year, this.month, this.day);
    };
    gregorianToEthiopic$1 = function (year, month, day) {
        var jdn = this.gregorianToJDN(year, month, day);

        return this.jdnToEthiopic$1(jdn, this.guessEraFromJDN(jdn));
    };

    /*
    ** ********************************************************************************
    **  Conversion Methods To/From the Julian Day Number
    ** ********************************************************************************
    */
    quotient = function (i, j) {
        return (Math.floor(i / j) | 0);
    };
    mod = function (i, j) {
        return ((i - (j * this.quotient(i, j))) | 0);
    };
    guessEraFromJDN = function (jdn) {
        return (jdn >= (this.JD_EPOCH_OFFSET_AMETE_MIHRET + 365)) ? this.JD_EPOCH_OFFSET_AMETE_MIHRET : this.JD_EPOCH_OFFSET_AMETE_ALEM;
    };
    isGregorianLeap = function (year) {
        return (year % 4 === 0) && ((year % 100 !== 0) || (year % 400 === 0));
    };
    jdnToGregorian = function (j) {
        var r2000 = this.mod((j - this.JD_EPOCH_OFFSET_GREGORIAN), 730485);
        var r400 = this.mod((j - this.JD_EPOCH_OFFSET_GREGORIAN), 146097);
        var r100 = this.mod(r400, 36524);
        var r4 = this.mod(r100, 1461);

        var n = this.mod(r4, 365) + 365 * this.quotient(r4, 1460);
        var s = this.quotient(r4, 1095);


        var aprime = 400 * this.quotient((j - this.JD_EPOCH_OFFSET_GREGORIAN), 146097) + 100 * this.quotient(r400,
            36524) + 4 * this.quotient(r100, 1461) + this.quotient(r4, 365) - this.quotient(r4, 1460) - this.quotient(r2000,
            730484);
        var year = aprime + 1;
        var t = this.quotient((364 + s - n), 306);
        var month = t * (this.quotient(n, 31) + 1) + (1 - t) * (this.quotient((5 * (n - s) + 13), 153) + 1);
        /*
        int day    = t * ( n - s - 31*month + 32 )
        + ( 1 - t ) * ( n - s - 30*month - quotient((3*month - 2), 5) + 33 )
        ;
        */

        // int n2000 = quotient( r2000, 730484 );
        n += 1 - this.quotient(r2000, 730484);
        var day = n;


        if ((r100 === 0) && (n === 0) && (r400 !== 0)) {
            month = 12;
            day = 31;
        }
        else {
            this.monthDays[2] = this.isGregorianLeap(year) ? 29 : 28;
            for (var i = 1; i <= this.nMonths; ++i) {
                if (n <= this.monthDays[i]) {
                    day = n;
                    break;
                }
                n -= this.monthDays[i];
            }
        }

        var result = [year, month, day];

        return result;
    };
    gregorianToJDN = function (year, month, day) {
        var s = this.quotient(year, 4) - this.quotient(year - 1, 4) - this.quotient(year, 100) + this.quotient(year - 1,
            100) + this.quotient(year, 400) - this.quotient(year - 1, 400);

        var t = this.quotient(14 - month, 12);

        var n = 31 * t * (month - 1) + (1 - t) * (59 + s + 30 * (month - 3) + this.quotient((3 * month - 7),
            5)) + day - 1;

        var j = this.JD_EPOCH_OFFSET_GREGORIAN + 365 * (year - 1) + this.quotient(year - 1, 4) - this.quotient(year - 1,
            100) + this.quotient(year - 1, 400) + n;

        return j;
    };
    jdnToEthiopic = function (jdn) {
        return this.isEraSet() ? this.jdnToEthiopic$1(jdn, this.jdOffset) : this.jdnToEthiopic$1(jdn,
            this.guessEraFromJDN(jdn));
    };
    jdnToEthiopic$1 = function (jdn, era) {
        var r = this.mod((jdn - era), 1461);
        var n = this.mod(r, 365) + 365 * this.quotient(r, 1460);

        var year = 4 * this.quotient((jdn - era), 1461) + this.quotient(r, 365) - this.quotient(r, 1460);
        var month = this.quotient(n, 30) + 1;
        var day = this.mod(n, 30) + 1;

        return [year, month, day];
    };
    ethiopicToJDN = function () {
        if (this.dateIsUnset) {
            throw new Error("Unset date.");
        }
        return this.ethiopicToJDN$2(this.year, this.month, this.day);
    };
    /**
    *  Computes the Julian day number of the given Ethiopic date.
    *  This method assumes that the JDN epoch offset has been set. This method
    *  is called by copticToGregorian and ethiopicToGregorian which will set
    *  the jdn offset context.
    *
    *  @param year a year in the Ethiopic calendar
    *  @param month a month in the Ethiopic calendar
    *  @param date a date in the Ethiopic calendar
    *
    *  @return The Julian Day Number (JDN)
    */
    ethCopticToJDN = function (year, month, day, era) {
        var jdn = (era + 365) + 365 * (year - 1) + this.quotient(year, 4) + 30 * month + day - 31;

        return jdn;
    };
    ethiopicToJDN$2 = function (year, month, day) {
        return this.isEraSet()
					? this.ethCopticToJDN(year, month, day, this.jdOffset)
					: this.ethCopticToJDN(year, month, day, this.JD_EPOCH_OFFSET_AMETE_MIHRET);
    };
    ethiopicToJDN$1 = function (era) {
        return this.ethiopicToJDN$3(this.year, this.month, this.day, era);
    };
    ethiopicToJDN$3 = function (year, month, day, era) {
        return this.ethCopticToJDN(year, month, day, era);
    };

    logDates = function () {
        this.log('Year: ' + this.year);
        this.log('month: ' + this.month);
        this.log('day: ' + this.day);
        this.log('date: ' + this.myDate);
    };
    log = function (message) {
        if (logCalEnabled)
            console.log('ZCal Log: ' + message);
    }
};


export default ZCal;