import * as rxjs from "rxjs";
import PouchDB from "pouchdb";
import { REMOTE_DB_URL } from "../Config";
import AuthBloc from "./AuthBloc";
import jwt_decode from "jwt-decode";
import PouchDbUtils from "../utils/PouchUtils";
import axios from "axios";

const DB_NAME_PUBHOL = "pubhol";

const calEventsSubject = new rxjs.BehaviorSubject(null);
const curMonthSubject = new rxjs.BehaviorSubject([]);
const selCalEventSubject = new rxjs.BehaviorSubject();

var _dbPrv = null;

const CalEventBloc = {
    subCalEvents: () => calEventsSubject.asObservable(),
    getCalEvents: () => calEventsSubject.getValue(),
    getCalEventsForDate: (d) => {
        const allPh = calEventsSubject.getValue();

        return allPh ? allPh[d.getTime()] : null;
    },

    subCurMonth: () => curMonthSubject.asObservable(),
    getCurMonth: () => curMonthSubject.getValue(),
    setCurMonth: (v) => curMonthSubject.next(v),
    addToCurMonth: (v) => {
        let a = curMonthSubject.getValue();
        if (!a) a = [];
        a.push(v);
        curMonthSubject.next(a);
    },

    subSelCalEvent: () => selCalEventSubject.asObservable(),
    getSelCalEvent: () => selCalEventSubject.getValue(),
    setSelCalEvent: (v) => selCalEventSubject.next(v),


    init: async () => {
        _populateCalEvents();

        const subApp = AuthBloc.subAuth().subscribe(auth => {
            if(!auth) return;     
            let token = auth.access_token;
            (async() => {
                try {
                    if(await _initRemoteDb(token)) {
                        _syncLocalDb(token);
                    }   
                } catch (error) {
                    console.log("Error initializing remote db: ", error);
                }
            })();

            const subPrv = AuthBloc.subPrvAuth().subscribe(prvToken => {
                if(prvToken) {
                    (async() => {
                        try {
                            if(await _initRemoteDb(token, prvToken)) {
                                _syncPrvLocalDb(prvToken);
                            }
                        } catch (error) {
                            console.log("Error initializing prv remote db: ", error);
                        }
                    })();
                }
            });
            
        });
    },
    savePubEvent: (calEvent) => {        
        console.log("savPubEvent: ", calEvent);
        const db = new PouchDB(DB_NAME_PUBHOL);
        db.post(calEvent).then(res => {
            CalEventBloc.setSelCalEvent(null);
            _populateCalEvents();
            _syncRemoteDb(db);
        }).catch(err => {
            console.log("Error when adding new public event", err);
        })
    },
    savePrivateEvent: (calEvent) => {
        console.log("savePrivateEvent: ", calEvent);
        if(_dbPrv) {
            _dbPrv.post(calEvent).then(res => {
                CalEventBloc.setSelCalEvent(null);
                _populateCalEvents();
                _syncPrvRemoteDb(_dbPrv);
            }).catch(err => {
                console.log("Error when adding new private event", err);
            })
        } else {
            throw("Local private db not found.");
        }
    },
    restorePubHolidays: async () => {
        let token = await AuthBloc.getToken();
        const srcDbRemote = new PouchDB((REMOTE_DB_URL + DB_NAME_PUBHOL), {
            fetch: (url, opts) => {
                opts.headers.set('Authorization', 'Bearer ' + token);
                return PouchDB.fetch(url, opts);
            }
        });

        srcDbRemote.allDocs({ include_docs: true }).then(res => {
            if(res.rows.length > 0) {
                (async() => {
                    if (await _deleteRemoteDb(token)) {
                        if (await _initRemoteDb(token)) {
                            const dbRemote = _getRemoteDb(token);
                            
                            res.rows.forEach(row => {
                                dbRemote.post({
                                    type: "et-pub-hol",
                                    day: row.doc.day,
                                    title: row.doc.title
                                })
                            });
            
                            const db = new PouchDB(DB_NAME_PUBHOL);
                            db.destroy().then(r => {
                                _syncLocalDb(token)
                            }).catch(err => {
                                console.log("Error removing local db: ", err)
                            })
                        }
                    }
                })();                
            } else {
                console.log("Source db has no records");
            }
        }).catch(err => {
            console.log("Error when checking records from source db", err)
        })        
    },
    deleteLocalPrvDb: () => {
        if(_dbPrv) {
            _dbPrv.destroy();
        }
        else {
            console.log("Private db not found.");
        }
    }
}

export default CalEventBloc;

function _syncLocalDb(token) {
    console.log(">>> Syncing local db")
    const dbRemote = _getRemoteDb(token);
    console.log("dbRemote: ", dbRemote);
    let dbLocal = new PouchDB(DB_NAME_PUBHOL);
    //dbLocal.destroy().then(res => {
        //console.log("Removed local db");
        //dbLocal = new PouchDB(DB_NAME_PUBHOL);
        dbRemote.replicate.to(dbLocal, {
            filter: (doc) => {
                return doc.type === "et-pub-hol";
            }
            , skip_setup: true
        }).then(res => {
            console.log("replicated from remote", res);
            _populateCalEvents();
        }).catch(err => {
            console.log("Error replicating..", err);
        });
    // }).catch(err => {
    //     console.log("Error removing local db", err);
    // })
}

function _syncPrvLocalDb(token) {
    console.log("Syncing local prv db");
    const dbRemote = _getRemoteDb(token);
    const localDbName = dbRemote.name.replace(REMOTE_DB_URL, '');
    _dbPrv = new PouchDB(localDbName);
     
    dbRemote.replicate.to(_dbPrv, {
        filter: (doc) => {
            return doc.type === "prv-cal-event";
        }
        , skip_setup: true
    }).then(() => {
        console.log("replicated from remote");
        _populateCalEvents();
    }).catch(err => {
        console.log("Error replicating..", err);
    });
}

async function _populateCalEvents() {
    const dbPub = new PouchDB(DB_NAME_PUBHOL);
    const allPh = new Map();
    
    console.log("updating public holidays");
    let res = await dbPub.allDocs({ include_docs: true });
    console.log("public holidays length: ", res.rows.length);
    if(res.rows.length) {
        res.rows.forEach(p => {
            let key = new Date(p.doc.day).getTime();
            let arr = allPh[key] || [];
            arr.push(p.doc);
            allPh[key] = arr;
        });
    }
    
    if(AuthBloc.hasPrvToken()) {
        console.log("updating private events");
        if(_dbPrv) {
            res = await _dbPrv.allDocs({ include_docs: true });
            console.log("private events: ", res.rows.length);
            if(res.rows.length) {
                res.rows.forEach(p => {
                    let key = new Date(p.doc.day).getTime();
                    let arr = allPh[key] || [];
                    p.doc.isPrivate = true;
                    arr.push(p.doc);
                    allPh[key] = arr;
                });
            }
        }  
        // else {
        //     throw("Private db not found.");
        // }  
    }
    calEventsSubject.next(allPh);
}

function _getRemoteDb(token) {
    var decoded = jwt_decode(token);
    var appDbName = REMOTE_DB_URL + PouchDbUtils.usernameToDbName(decoded.sub); 

    const dbRemote = new PouchDB(appDbName, {
        fetch: (url, opts) => {
            opts.headers.set('Authorization', 'Bearer ' + token);
            return PouchDB.fetch(url, opts);
        }, 
        skip_setup: true
    });
    return dbRemote;
}

async function _initRemoteDb(token, prvToken) {
    var jwt = jwt_decode(prvToken || token);
    var dbName = REMOTE_DB_URL + PouchDbUtils.usernameToDbName(jwt.sub); 

    console.log("initializing remote db: ", dbName)
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    try {
        const res = await axios.get(dbName, config); 
        console.log("Remote db exists: ", res)
        await _initDbPermission(dbName, config, jwt.sub, jwt["_couchdb.roles"]);
        console.log("Remote db permission set")
    } catch ({response}) {
        console.log("remote db err: ", response);
        if(response && response.status === 404) {
            try {
                const res = await axios.put(dbName, null, config);
                console.log("Remote db created: ", res);
                await _initDbPermission(dbName, config, jwt.sub, jwt["_couchdb.roles"]);
                console.log("Remote db permission set")
            } catch (error) {
                console.log("Error creating remote db: ", error);
            }
        } else {
            return false;
        }
    }
    return true;    
}

async function _deleteRemoteDb(token) {
    var jwt = jwt_decode(token);
    var appDbName = REMOTE_DB_URL + PouchDbUtils.usernameToDbName(jwt.sub); 
    console.log("Remote db to delete: ", appDbName);

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    try {
        let res = await axios.delete(appDbName, config); 
        console.log("Remote db deleted", res)
    } catch ({response}) {
        console.log("remote db err: ", response);
        if(response && response.status === 404) {
            return true;
        }
        return false;
    }
    return true;  
}

function _initDbPermission(appDbName, config, userName, userRoles) {
    let roles = [];
    if (userRoles && userRoles.length) {
        roles = [...userRoles];
    }
    console.log("*** roles: ", roles);
    if (!roles.includes("_admin"))
        roles.push("_admin");

    const data = {
        "members": {
            "names": [userName],
            "roles": roles
        },
        "admins": {
            "roles": roles
        }
    };

    return axios.put(
        appDbName + "/_security",
        data,
        config
    );
}

async function _syncRemoteDb(localDb) {
    if(AuthBloc.hasToken()) {
        const token = await AuthBloc.getToken();
        const remoteDB = _getRemoteDb(token);
        remoteDB.replicate.from(localDb, {
            skip_setup: true
        }).then(res => {
            console.log("Updated remote db: ", res);
        }).catch(err => {
            console.log("Error updating remote db: ", err);
        });
    }
}

function _syncPrvRemoteDb(localDb) {
    if(AuthBloc.hasPrvToken) {
        const token = AuthBloc.getPrvToken();
        const remoteDB = _getRemoteDb(token);
        remoteDB.replicate.from(localDb, {
            skip_setup: true
        }).then(res => {
            console.log("Updated remote prv db: ", res);
        }).catch(err => {
            console.log("Error updating remote prv db: ", err);
        });
    }
}
