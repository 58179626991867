import { Box, Slide } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import CalBloc from '../Bloc/CalBloc';

const SwipeableDiv = ({children}) => {
    const [direction, setDirection] = useState('down');
    const [selected, setSelected] = useState(true);
    const containerRef = React.useRef(null);

    const showNextMonth = (direction) => {
        setSelected(false);
        CalBloc.addToMonth(1);
        setDirection(direction);
        setTimeout(() => {                    
            setSelected(true);
        }, 1);
    }
    const showLastMonth = (direction) => {
        setSelected(false);
        CalBloc.addToMonth(-1);
        setDirection(direction);
        setTimeout(() => {                    
            setSelected(true);
        }, 1);
    }
    const handlers = useSwipeable(
        { 
            onSwipedLeft: () => showNextMonth('left'), 
            onSwipedUp: () => showNextMonth('up'),
            onSwipedRight: () => showLastMonth('right'),
            onSwipedDown: () => showLastMonth('down'),
            preventScrollOnSwipe: true
        })

    useEffect(()=> {
        CalBloc.subMonth().subscribe(v => {

            
        })
    }, [])
    return <div {...handlers} >
        <Box sx={{
            
            overflow: 'hidden',
        }}
       ref={containerRef} >
            <Slide direction={direction} in={selected} 
                mountOnEnter unmountOnExit 
                timeout={{enter: 500, exit: 0}}
                container={containerRef.current}>
                {children}
            </Slide>
        </Box>
    </div>
}
export default SwipeableDiv;