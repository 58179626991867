import env from "@beam-australia/react-env";

export const logEnabled = true
export const logCalEnabled = false;

export const COOKIE_MAX_AGE_SEC = (60 * 60 * 24 * 365);

export const POUCH_DB = {
  // fetchOnDemand:
  //  - true: only initial replication of pouchdb from couchdb and 
  //          change on individual items is not monitored but any change to the db
  //          is monitored and a callback function is called.
  //  - false: After initial replication, all data is fetched from
  //          pouchdb and individual changes are monitored and changed document is 
  //          passed to call back function
  syncedDbs: [
    {
      couchDbUrl: env("COUCHDB_URL") ? env("COUCHDB_URL") : "",
      couchDbAuth: env("COUCHDB_AUTH") ? env("COUCHDB_AUTH") : "",
      name: "",
      fetchOnDemand: true,
    }
  ]
}

export const REMOTE_DB_URL = env("REMOTE_DB_URL") || "";
export const AUTH_TOKEN_URL = env("AUTH_TOKEN_URL") || ""

export const AUTH_TOKEN_CONF =  {
  client_id: env("AUTH_CLIENT_ID") || "",
  grant_type: env("AUTH_GRANT_TYPE") || "",
  client_secret: env("AUTH_CLIENT_SECRET") || "",
  scope: env("AUTH_SCOPE") || ""
};

export const LOG_ENABLED = env("LOG_ENABLED") || false;