import React from 'react';
import Grid from '@mui/material/Grid';
import CalBloc from '../Bloc/CalBloc'

const DateInfo = (props) => {

    return (
        <Grid container >
            <Grid item xs={4} style={{ textAlign: "left"}}>
                {CalBloc.getCurrentMonthName()}
            </Grid>
            <Grid item xs={4}
                style={{ textAlign: "center", fontSize: "small", padding: "0.25rem 0" }}>
                {`${CalBloc.getCalET() ? "Ethiopian" : "Gregorian"} Calendar`}

            </Grid>
            <Grid item xs={4} className="amh" style={{ textAlign: "right"}}>
                {CalBloc.getCurrentMonthNameForSecCal()}
            </Grid>

        </Grid>
    )
}
export default DateInfo;