import React from 'react';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageFooter from './components/PageFooter';
import CacheUpdate from './components/CacheUpdate';
import AuthBloc from './Bloc/AuthBloc';
import PrivateRoute from './utils/PrivateRoute';
import LogUtils from './utils/LogUtils';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, useTheme, createTheme } from '@mui/material/styles';
import PageHeader from './components/PageHeader';
import Admin from './Admin';
import Home from './Home';
import About from './About';

import Box from '@mui/material/Box';
import { green, grey, lime, lightGreen } from '@mui/material/colors';
import { Container } from '@mui/material';
import { LOG_ENABLED } from './Config';
import "./App.css"

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      ...lightGreen,
      ...(mode === 'dark' && {
        main: lightGreen[800],
      }),
    },
    ...(mode === 'dark' && {
      background: {
        default: '#000',
        paper: '#000',
      },
    }),
    secondary: {
      ...lime,
      ...(mode === 'dark' && {
        main: lime[800],
      }),
    },
    ...(mode === 'dark' && {
      background: {
        default: '#000',
        paper: '#000',
      },
    }),
    text: {
      ...(mode === 'light'
        ? {
          primary: grey[900],
          secondary: grey[800],
        }
        : {
          primary: lightGreen[800],
          secondary: lime[800],
        }),
    },
  },
});

// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//   },
// });

const darkModeTheme = createTheme(getDesignTokens('dark'));

function App() {

  (async () => {
    if (LOG_ENABLED) {
      LogUtils.enableLog();
    } else {
      await LogUtils.disableLog();
    }
    AuthBloc.init();
  })();

  return (
    <ThemeProvider theme={darkModeTheme}>
      <CssBaseline enableColorScheme />
      <CacheUpdate />
      <ReactKeycloakProvider authClient={keycloak}>
        <React.StrictMode>
          <BrowserRouter>
            <PageHeader />
            <Container sx={{p: '.25rem'}}>
            <Routes>
              <Route path="/admin"
                element={
                  <PrivateRoute>
                    <Admin />
                  </PrivateRoute>
                } />
              <Route path="/about" element={<About />} />
              <Route path="*" element={<Home />} />
            </Routes>
            </Container>
            <PageFooter />
          </BrowserRouter>
        </React.StrictMode>
      </ReactKeycloakProvider>
    </ThemeProvider>
  );
}

export default App;
